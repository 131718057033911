.footer {
  background-color: #f8f9fa;
  padding: 2em 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 3px solid #6EE7B7;
  flex-wrap: wrap;
}

.contact-info {
  display: flex;
  align-items: center;
  gap: 2em;
}

.contact-info a {
  color: inherit;
  text-decoration: none;
  transition: transform 0.2s;
}

.contact-info img {
  transition: transform 0.2s;
}

.contact-info img:hover {
  transform: translateY(-5px);
}

.footer-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #6EE7B7;
  color: #444;
  font-weight: 600;
  transition: transform 0.2s, background-color 0.2s;
  cursor: pointer;
  text-transform: uppercase;
}

.footer-button:hover {
  transform: translateY(-5px);
  background-color: #3FA7D6;
}

/* Small Devices */
@media (max-width: 768px) {
  .contact-info {
      gap: 1.5em;
  }
}

/* Extra Small Devices */
@media (max-width: 576px) {
  .footer {
      flex-direction: column;
      gap: 1em;
  }

  .contact-info {
      justify-content: center;
  }
}

