#experience-section {
    font-family: 'Arial', sans-serif;
    background-color: #f7f7f9;
    padding: 60px 5%;
    margin-top: 2vh;
}

h2 {
    font-size: 2.5em;
    margin-bottom: 30px;
    color: #333;
    text-align: center;
    border-bottom: 3px solid #666;
    display: inline-block;
    padding-bottom: 5px;
}

.job-entry {
    margin-bottom: 60px;
    margin-left: 2.5vw;
}

h3 {
    font-size: 2em;
    margin-bottom: 10px;
    color: #444;
}

.company {
    font-size: 1.5em;
    font-style: italic;
    color: #555;
    margin-bottom: 20px;
}

ul {
    list-style-type: square;
    color: #555;
    margin-left: 20px;
}

li {
    margin-bottom: 15px;
    font-size: 1.1em;
}

/* Medium devices (tablets) */
@media (max-width: 992px) {
    h2 {
        font-size: 2em;
    }

    h3 {
        font-size: 1.8em;
    }

    .company {
        font-size: 1.3em;
    }
}

/* Small devices (landscape phones) */
@media (max-width: 768px) {
    #work-experience-section {
        padding: 50px 5%;
    }

    h2 {
        font-size: 1.8em;
    }

    h3 {
        font-size: 1.6em;
    }

    .company {
        font-size: 1.2em;
    }

    ul {
        margin-left: 15px;
    }

    li {
        font-size: 1em;
    }
}

/* Extra small devices (portrait phones) */
@media (max-width: 576px) {
    #experience-section {
        padding: 40px 5%;
    }

    h2 {
        font-size: 1.6em;
    }

    h3 {
        font-size: 1.4em;
    }

    .company {
        font-size: 1.1em;
    }

    ul {
        margin-left: 10px;
    }

    li {
        font-size: 0.9em;
    }
}
