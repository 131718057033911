.header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 88vh;
    width: 100%;
    background: linear-gradient(135deg, #6EE7B7 0%, #3FA7D6 100%);
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 2em;
    position: relative;
    overflow: hidden;
    flex-direction: column;
}

.header .subheading {
    color: #fff;
    font-size: 1.2em;
    margin-top: 20px;
    font-weight: normal;
    text-transform: none;
    letter-spacing: normal;
    border-bottom: none;
}

/* Medium devices (tablets) */
@media (max-width: 992px) {
    .header {
        font-size: 1.6em;
    }

    .header .subheading {
        font-size: 1.1em;
    }
}

/* Small devices (landscape phones) */
@media (max-width: 768px) {
    .header {
        font-size: 1.4em;
    }

    .header .subheading {
        font-size: 1em;
    }
}

/* Extra small devices (portrait phones) */
@media (max-width: 576px) {
    .header {
        font-size: 1.2em;
    }

    .header .subheading {
        font-size: 0.9em;
    }
}
