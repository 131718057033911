.hamburger{
  display: none; 
  color: grey;
  font-size: 24px;
  cursor: pointer;
  z-index: 1000;
  
  /* Positioning */
  position: fixed;
  top: 10px; 
  right: 10px;
  padding: 5px; 
}

nav {
  margin: 0;
  padding: 1rem;
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #222;
}

nav a {
  text-decoration: none;
  color: inherit;
}

.menu-button {
  background-color: #444;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  font-weight: 500;
  transition: background 0.3s, transform 0.3s;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 2px 2px 5px rgba(100, 100, 100, 0.5);
}

.menu-button:hover {
  background: #555;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(100, 100, 100, 0.5);
}

.menu-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(100, 100, 100, 0.5);
}

.menu-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(100, 100, 100, 0.5);
}

/* Medium devices (tablets) */
@media (max-width: 992px) {
  .menu-button {
    padding: 8px 16px;
    margin: 8px;
    font-size: 0.9em;
  }
}

/* Small devices (landscape phones) */
@media (max-width: 768px) {
  .hamburger {
    font-size: 5vh;
    display: block;
  }

  nav {
    flex-direction: column;
    justify-content: space-evenly;
    width: 0;
    height: 100%;
    background-color: #222;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    right: 0;
    transition: 0.5s;
    z-index: 999;
    margin: 0 0;
    padding: 0 0;
  }

  nav.open {
    width: 60%;
    height: 100vh;
  }

  nav.open .hamburger {
    display: none;
  }

  .menu-button {
    width: 60%;
    box-sizing: border-box;
  }
}

/* Extra small devices (portrait phones) */
@media (max-width: 576px) {
  nav {
    flex-direction: column;
    justify-content: space-evenly;
    height: 17.5vh;
    margin: 0 0;
    padding: 0 0;
  }

  .menu-button {
    width: 60%;
    font-size: 0.8em;
    text-align: center;
  }
}
