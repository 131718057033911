#about-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20vh 10%;
    background-color: #f8f9fa;
}

.about-content {
    max-width: 1100px;
    display: flex;
    gap: 50px;
    align-items: center;
}

.about-image {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}

.about-text {
    flex: 1;
}

.about-text h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
}

.about-text p {
    font-size: 1em;
    line-height: 1.6;
}

/* Medium devices (tablets) */
@media (max-width: 992px) {
    .about-text h2 {
        font-size: 2em;
    }

    .about-text p {
        font-size: 0.95em;
    }
}

/* Small devices (landscape phones) */
@media (max-width: 768px) {
    .about-content {
        flex-direction: column;
        gap: 30px;
    }

    .about-text h2 {
        font-size: 1.75em;
    }

    .about-image {
        width: 200px;
        height: 200px;
    }
}

/* Extra small devices (portrait phones) */
@media (max-width: 576px) {
    #about-section {
        padding: 15vh 5%;
    }

    .about-text {
        text-align: center;
    }

    .about-text h2 {
        font-size: 1.5em;
    }

    .about-image {
        width: 150px;
        height: 150px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}
