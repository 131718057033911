#skills-section {
    background-color: #f8f9fa;
    padding: 60px 5%;
    border-radius: 8px;
    margin-top: 2vh;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.skills-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.skills-category {
    flex: 1 0;
    min-width: 250px;
    margin: .4rem;
    background-color: #ffffff;
    padding: 1em;
    border-radius: 8px;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.05);
}

.skills-category h3 {
    border-bottom: 2px solid #6EE7B7;
    margin-bottom: 1em;
    padding-bottom: 0.5em;
}

.skills-category ul {
    list-style-type: none;
    padding: 0;
}

.skills-category li {
    padding: 0.5em 0;
    font-size: 0.9em;
}

.title-bar {
    height: 5vh;
    display: flex;
    align-items: center;
    margin-bottom: 5vh;
}

.title-bar h2 {
    font-size: 2.5em;
    margin: 0;
    margin-right: 2vw;
    color: #333;
    border-bottom: 3px solid #666;
    padding: 0;
}

.title-bar img {
    max-height: 70px;
    margin: 0 1vw;
}

/* Extra Medium devices */

@media (max-width: 1416px) {
    .skills-category {
        min-width: 40vw;
    }
}

/* Medium devices (tablets) */
@media (max-width: 992px) {
    .skills-container {
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
        margin-left: 2.5vw;
    }

    .skills-category {
        width: 90%;
        margin: 0vw;
        margin-bottom: 2vh;
    }

    .title-bar {
        margin-bottom: 5vh;
    }
}

/* Small devices (landscape phones) */
@media (max-width: 768px) {
    #skills-section {
        padding: 50px 5%;
    }

    .skills-category {
        width: 80vw;
        margin-bottom: 1em;
    }

    .skills-category h3 {
        font-size: 1.1em;
    }

    .skills-category li {
        font-size: 0.85em;
    }
}

/* Extra small devices (portrait phones) */
@media (max-width: 576px) {
    #skills-section {
        padding: 40px 5%;
    }

    .title-bar {
        margin-bottom: 7.5vh;
        margin-left: 1rem;
    }

    .title-bar h2 {
        margin-right: 1rem;
    }

    .title-bar img {
        margin-right: 0;
    }

    .skills-category {
        min-width: 70vw;
        max-width: 75vw;
    }

    .skills-category h3 {
        font-size: 1em;
    }
}
