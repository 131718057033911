body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  color: #333;
  background-color: #f4f4f4;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #555;
}

nav {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

section {
  padding: 20px 0;
  border-bottom: 1px solid #ddd;
}

header, h1, h2 {
  text-align: center;
}

h1, h2 {
  margin-top: 0;
}